
export const environment = {
  production: false,

  apiUrls: {
    api: 'https://xmlapi.secondhome.dev'
     //api: 'http://localhost:3000'
  },
  name: 'dev'
};
