
export class Xml {
  id?: number = null;
  url = '';
  username = '';
  rootKey = '';
  fields: any = [];
  data: any[];
  filters: XmlFilters[];
  translation: Translation[];
  constructor() {
  }
  reset() {
    this.id = null;
    this.url = '';
    this.username = '';
  }
}

export class SearchReplace {
  search: String;
  replace: String;
  field: String;
}

export class XmlFilters {
  field: String;
  operator: '' | 'CONTAINS' | 'EQUALS' | 'LESS_THAN' | 'GREATER_THAN'
  value: String;
}

export enum Lang {
  BG = "Bulgarian",
  CS = "Czech",
  DA = "Danish",
  DE = "German",
  EL = "Greek",
  "EN-GB" = "English (British)",
  "EN-US" = "English (American)",
  EN = "English",
  ES = "Spanish",
  ET = "Estonian",
  FI = "Finnish",
  FR = "French",
  HU = "Hungarian",
  IT = "Italian",
  JA = "Japanese",
  LT = "Lithuanian",
  LV = "Latvian",
  NL = "Dutch",
  PL = "Polish",
  "PT-PT" = "Portuguese (excluding Brazilian Portuguese)",
  "PT-BR" = "Portuguese (Brazilian)",
  PT = "Portuguese",
  RO = "Romanian",
  RU = "Russian",
  SK = "Slovak",
  SL = "Slovenian",
  SV = "Swedish",
  ZH = "Chinese",
}

export class Translation {
  field: String;
  sourceLang: "auto"| "" | Lang
  targetLang: "" | Lang;
}

