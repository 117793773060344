import { Component, OnInit } from '@angular/core';
import { Xml, SearchReplace, XmlFilters, Translation, Lang } from 'src/app/models/xml';
import { Page } from 'src/app/shared/common/contracts/page';
import { XMLService } from 'src/app/services/xml.service';
import { DynamicGrid } from 'src/app/models/grid.model';
import { User } from 'src/app/models/user';
import { Newxml } from 'src/app/models/newxml.model';
import { Router } from '@angular/router';
import { MatBottomSheet, MatSnackBar } from '@angular/material';
import { ChangePassComponent } from 'src/app/modals/change-pass/change-pass.component';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';

declare var i;

@Component({
  selector: 'nmdx-xml',
  templateUrl: './xml.component.html',
  styleUrls: ['./xml.component.css']
})



export class XmlComponent implements OnInit {
  isLoading: Boolean = false;
  rootUrl: String = '';
  xmlId: String = '';
  private sub: any;
  state = '';
  name: string;
  id: number;
  isEdit = false;
  show = false;
  showViewBtn: Boolean = false;
  isSelected = false;
  user: User;
  link = '';
  roll = '';
  xml: Xml = new Xml();
  xmls: Page<Xml>;
  data: any;
  fieldsArray: Array<Newxml> = [];
  searchReplace: Array<SearchReplace> = [];
  xmlFilters: Array<XmlFilters> = [];
  translations: Array<Translation> = [];
  newFields: any = {};
  demoRow = [
    { test: 'Id', id: 0 },
  ];
  languages = this.ToArray(Lang)
  kyeroFields = [
    'id',
    'date',
    'ref',
    'price',
    'currency',
    'price_freq',
    'part_ownership',
    'leasehold',
    'new_build',
    'type',
    'town',
    'province',
    'country',
    'latitude',
    'longitude',
    'location',
    'location_detail',
    'beds',
    'baths',
    'pool',
    'built',
    'plot',
    'surface_area',
    'consumption',
    'emissions',
    'energy_rating',
    'url',
    'zip',
    'desc-ca',
    'desc-de',
    'desc-da',
    'desc-en',
    'desc-es',
    'desc-fi',
    'desc-fr',
    'desc-it',
    'desc-nl',
    'desc-no',
    'desc-pt',
    'desc-ru',
    'desc-sv',
    'title-ca',
    'title-de',
    'title-da',
    'title-en',
    'title-es',
    'title-fi',
    'title-fr',
    'title-it',
    'title-nl',
    'title-no',
    'title-pt',
    'title-ru',
    'title-sv',
    'features-feature',
    'notes',
    'images-image-url',
    'region',
    'postcode',
    'construction_year',
    'video_url'
  ];
  xmlFields = [];
  newValue = '';
  testValue = '';

  filters = [
    'Id',
    'prijs',
    'title',
    'land',
    'foto0',
    'land_en',
    'provincie',
    'plaats',
    'lat',
    'perceeloppervlak',
    'long',
    'aantalSlaapkamers',
    'aantalBadkamers',
    'woonlagen',
    'soortbouw',
    'soortaanbod',
    'beschrijving',
    'deeplinkURL',
  ];
  // selectedCity = this.filters[1];
  step = 0;

  constructor(
    private _xmlService: XMLService,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) {
    this.user = JSON.parse(window.localStorage.getItem('user'));
    this.rootUrl = `${environment.apiUrls.api}/api`;
    this.xmls = new Page({
      api: _xmlService.xmls
    });
    this.fetch();
  }


  // Turn enum into array
  ToArray(enumme) {
    return Object.keys(enumme)
      .map(key => ({label: `${key}-${enumme[key]}`, value: key}));
  }

  edit() {
    this.isEdit = true;
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  replaceAt(array, index, value) {
    const ret = array.slice(0);
    ret[index] = value;
    return ret;
  }

  reset() {
    this.name = '';
  }

  onTest(name, id) {
    console.log(name, 'name');
    if (id) {
      // Find Index
      const index = this.demoRow.findIndex(data => (data.id === id));
      console.log(index, 'index');
      // Data Filter
      const newData = this.demoRow.filter(data => (data.id === id))[0];
      newData.test = name;
      console.log(newData, 'newData');
      // Replace Value
      this.replaceAt(this.demoRow, index, newData);
      this.newValue = '';
      console.log(this.demoRow, 'change value');
    } else {
      // this.filters.push({ name, id });
      this.newValue = '';
    }
    this.show = true;
  }


  onButtonClicked(name, id) {
    // console.log(name, 'name');
    // if (id) {
    //   // Find Index
    //   const index = this.filters.findIndex(data => (data.id === id));
    //   console.log(index, 'index');
    //   // Data Filter
    //  // const newData = this.filters.filter(data => (data.id === id))[0];
    //   //newData.name = name;
    //   console.log(newData, 'newData');
    //   // Replace Value
    //   this.replaceAt(this.filters, index, newData);
    //   this.newValue = '';
    //   console.log(this.filters, 'change value');
    // } else {
    //   //this.filters.push({ name, id });
    //   console.log(this.filters, 'save value');

    //   this.newValue = '';
    // }
    // this.show = true;
  }


  onClicked(state, id) {
    // this.state = 'title';
    // // Find Index
    // const index = this.values.findIndex(data => (data.id === id));
    // console.log(index, 'index');
    // // Data Filter
    // const newData = this.values.filter(data => (data.id === id))[0];
    // newData.name = state;
    // console.log(newData, 'newData');
    // // Replace Value
    // this.replaceAt(this.values, index, newData);
    // console.log(this.values, 'change value');
    // this.show = true;
  }

  add() {
    if (!this.xml.username) {
      alert('Please Select username');
    }
    if (!this.xml.url) {
      alert('Please Select URL');
    }
    this.isLoading = true;
    this._xmlService.xmls.create(this.xml).then((res) => {

      this.isLoading = false;
      this.xmlId = res['_id'];


      // Remove iT
      // ENd Remove it
      this.getXmlFields();
      this.xmls.fetch();
      this.addRow();
      this.addSrRow();
      this.addXmlFiltersRow();
      this.addTranslationRow();
      this.show = true;
      this.openSnackBar('XML Loaded Successfully',
        '');
      // this.xml.reset();
    });
  }

  addRow() {
    this.newFields = { keryoField: '', xmlField: '', defaultValue: '' };
    this.fieldsArray.push(this.newFields);
    return true;
  }

  addSrRow() {
    const srFields = { search: '', replace: '', field: '' };
    this.searchReplace.push(srFields);
    return true;
  }

  deleteSrRow(index) {
    if (this.searchReplace.length === 1) {
      return false;
    } else {
      this.searchReplace.splice(index, 1);
      return true;
    }
  }

  addXmlFiltersRow() {
    const row: XmlFilters = { field: '', operator: '', value: '' };
    this.xmlFilters.push(row);
    return true;
  }

  addTranslationRow() {
    const row: Translation = { field: '', sourceLang: 'auto', targetLang: '' };
    this.translations.push(row);
    return true;
  }

  deleteTranslationRow(index) {
    this.translations.splice(index, 1);
  }

  deleteXmlFiltersRow(index) {
    this.xmlFilters.splice(index, 1);
  }

  deleteRow(index) {
    if (this.fieldsArray.length === 1) {
      return false;
    } else {
      this.fieldsArray.splice(index, 1);
      return true;
    }
  }

  handleSelect(selectedValue, fieldsName) {

    const selectedFields = this.fieldsArray.map(el => el[fieldsName]);

    return selectedFields.includes(selectedValue);

  }

  ngOnInit(): void {
    this.newFields = { keryoField: '', xmlField: '', defaultValue: '' };
    this.fieldsArray.push(this.newFields);

    this.sub = this.route.params.subscribe(params => {
      // (+) converts string 'id' to a number

      if (params['id']) {
        this.xmlId = params['id'];
        this.getSingleXml();
        this.getXmlFields();
      }
      // In a real app: dispatch action to load the details here.
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  fetch() {
    this.xmls.fetch();
  }

  logOut() {
    window.localStorage.clear();
    this.router.navigate(['/signin']);
  }


  openBottomSheet(): void {
    this.bottomSheet.open(ChangePassComponent, { disableClose: true });
  }

  viewXml() {
    window.open(`https://xmlapi.secondhome.dev/api/xmls/parse2/${this.xmlId}`, '_blank');
  }

  getXmlFields() {
    this._xmlService.getXmlFields(this.xmlId).then((result) => {
      this.xmlFields = result.fields;
    });
  }

  getSingleXml() {
    this.isLoading = true;
    this._xmlService.getSingleXml(this.xmlId).then((result) => {
      this.fieldsArray = result.data.updateFields;
      this.searchReplace = result.data.searchReplace;
      this.xmlFilters = result.data.filters;
      this.translations = result.data.translations || [];
      this.xml.username = result.data.username;
      this.xml.url = result.data.url;
      this.xml.rootKey = result.data.rootKey;
      this.isLoading = false;
      this.show = true;
    });
  }

  run(evt: MouseEvent) {
    const payload = {
      id: this.xmlId,
      rootKey: this.xml.rootKey,
      mapFields: this.fieldsArray,
      srFields: this.searchReplace,
      filters: this.xmlFilters,
      translations: this.translations
    };

    this.isLoading = true;
    this._xmlService.updateXml(payload).subscribe(
      data => {
        // refresh the list
        console.log(data);
        this.isLoading = false;
        this.showViewBtn = true;
        return true;
      },
      error => {
        console.error('Error saving food!');
      }
    );

    // this.link = '/edit-column';
    // this.router.navigate(['link']);

  }


}
